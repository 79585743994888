import { useEffect, useState, useCallback, useRef } from "react";
import Card from "../components/Card";
import axios from "axios";
import { useAuth } from "../context/authContext";
import ConfirmationModal from "../components/ConfirmationModal";
import Modal from "../components/Modal";
import Filter from "../components/Filter";
import TimeshareMap from "../components/timeshareMap";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [timeShares, setTimeShares] = useState([]);
  const [filteredTimeShares, setFilteredTimeShares] = useState([]);
  const [filters, setFilters] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedTimeshare, setSelectedTimeshare] = useState(null);
  const [expandedLocation, setExpandedLocation] = useState(null);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [availableWeeks, setAvailableWeeks] = useState([]);
  const [availableBedCounts, setAvailableBedCounts] = useState([]);
  const [availableSaunaOptions, setAvailableSaunaOptions] = useState([]);
  const [availableBalconyOptions, setAvailableBalconyOptions] = useState([]);
  const [discountCode, setDiscountCode] = useState("");
  const [discountValue, setDiscountValue] = useState(0);
  const [discountErrorMessage, setDiscountErrorMessage] = useState("");
  const [isBooking, setIsBooking] = useState(false);
  const [modalConfirmCallback, setModalConfirmCallback] = useState(null);
  const [discountApplied, setDiscountApplied] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;
  const { user, updateUserCredits, loading } = useAuth();

  const navigate = useNavigate();
  const locationRef = useRef(null);

  useEffect(() => {
    console.log("user", user);
    if (!loading) {
      if (user?.membership?.active === 0) {
        navigate("/tervetuloa");
      }
    }
  }, [user, navigate, loading]);

  // Fetch timeshares once when the component mounts
  useEffect(() => {
    const fetchTimeShares = async () => {
      try {
        const response = await fetch(`${apiUrl}/timeshares/listed`, {
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("data", data);

        setTimeShares(Array.isArray(data) ? data : []);
        updateUserCredits();
      } catch (error) {
        console.error("Error fetching timeShares:", error);
      }
    };

    fetchTimeShares();
  }, [apiUrl, updateUserCredits]);

  useEffect(() => {
    const handleClickInside = (event) => {
      if (locationRef.current && locationRef.current.contains(event.target)) {
        const clickedLocation = event.target.closest("button");
        if (
          !clickedLocation ||
          !Object.keys(locations).includes(
            clickedLocation.innerText.toLowerCase()
          )
        ) {
          setExpandedLocation(null); // Collapse the expanded location if clicked outside a location button
        }
      }
    };

    if (locationRef.current) {
      // Add the event listener if the ref is not null
      locationRef.current.addEventListener("mousedown", handleClickInside);
    }

    return () => {
      // Cleanup the event listener when the component unmounts or ref changes
      if (locationRef.current) {
        locationRef.current.removeEventListener("mousedown", handleClickInside);
      }
    };
  }, [locations]);

  const checkAvailability = useCallback(
    (filterKey, filterValue) => {
      switch (filterKey) {
        case "location":
          return timeShares.some((ts) => ts.location === filterValue);
        case "week":
          return timeShares.some((ts) => {
            const startDate = new Date(ts.start_date);
            return getISOWeek(startDate) === parseInt(filterValue);
          });
        case "bedCount":
          return timeShares.some(
            (ts) => ts.sleeping_places >= parseInt(filterValue)
          );
        case "sauna":
          return timeShares.some((ts) => ts.sauna === 1); // Use 1 for sauna filter
        case "balcony":
          return timeShares.some((ts) => ts.balcony_terrace === 1); // Use 1 for balcony filter
        default:
          return false;
      }
    },
    [timeShares]
  );

  // applyFilters is now safely guarded with useCallback
  const applyFilters = useCallback(() => {
    let filtered = [...timeShares];

    // Filter by location if selected
    if (filters.location) {
      filtered = filtered.filter((ts) => ts.location === filters.location);
    }

    // Calculate available options based on filtered timeshares
    const availableWeeks = new Set();
    const availableBedCounts = new Set();
    const availableSaunaOptions = new Set();
    const availableBalconyOptions = new Set();

    filtered.forEach((ts) => {
      const startDate = new Date(ts.start_date);
      availableWeeks.add(getISOWeek(startDate));
      availableBedCounts.add(ts.sleeping_places);
      if (ts.sauna) {
        availableSaunaOptions.add(true);
      }
      if (ts.balcony_terrace) {
        availableBalconyOptions.add(true);
      }
    });

    setAvailableWeeks(Array.from(availableWeeks));
    setAvailableBedCounts(Array.from(availableBedCounts).sort((a, b) => a - b));
    setAvailableSaunaOptions(Array.from(availableSaunaOptions));
    setAvailableBalconyOptions(Array.from(availableBalconyOptions));

    // Apply filters to get filtered timeshares
    if (filters.week) {
      filtered = filtered.filter((ts) => {
        const startDate = new Date(ts.start_date);
        return getISOWeek(startDate) === parseInt(filters.week);
      });
    }

    if (filters.bedCount) {
      filtered = filtered.filter(
        (ts) => ts.sleeping_places >= parseInt(filters.bedCount)
      );
    }

    if (filters.qualities === "sauna") {
      filtered = filtered.filter((ts) => ts.sauna === 1);
    }

    if (filters.qualities === "balcony") {
      filtered = filtered.filter((ts) => ts.balcony_terrace === 1);
    }

    setFilteredTimeShares(filtered);
  }, [filters, timeShares]);

  // Only apply filters when either timeShares or filters change
  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const onFilterChange = (newFilters) => {
    setFilters((prevFilters) => {
      // Only update filters if they actually change to prevent re-renders
      if (JSON.stringify(newFilters) !== JSON.stringify(prevFilters)) {
        return newFilters;
      }
      return prevFilters;
    });
  };

  const onBookingClick = (timeshare) => {
    if (user.credits >= timeshare.adjustedCredits && !checkOffer(timeshare)) {
      // User has enough credits, proceed with standard payment
      initiateStandardPayment(timeshare);
    } else if (checkOffer(timeshare)) {
      // Offer available, initiate offer payment
      initiateOfferPayment(timeshare);
    } else {
      // Not enough credits, initiate payment with missing credits
      initiateInsufficientCreditsPayment(timeshare);
    }
  };

  const handleBookingClick = (timeshare, adjustedCredits) => {
    setSelectedTimeshare({ ...timeshare, adjustedCredits });

    const handleConfirm = async (discountCode) => {
      console.log(timeshare, adjustedCredits);

      try {
        // Call validateDiscountCode and handle errors
        await validateDiscountCode(discountCode, timeshare, adjustedCredits);

        // Clear error message if validation succeeds
        setErrorMessage("");

        // Proceed with booking
        onBookingClick(timeshare);
      } catch (error) {
        console.log("error", error);

        setDiscountErrorMessage("Virhe alennuskoodin tarkistuksessa.");
      }
    };

    if (checkOffer(timeshare)) {
      setMessage("Haluatko varmasti varata tämän viikon 99,90€?");
    } else if (user.credits >= adjustedCredits && !checkOffer(timeshare)) {
      setMessage(
        `Haluatko varmasti varata tämän viikon ${adjustedCredits} lomakrediitillä + 129,90€ vaihtomaksu?`
      );
    } else {
      const missingCredits = adjustedCredits - user.credits;
      const additionalCost = missingCredits * 1;
      setMessage(
        `Sinulla ei ole tarpeeksi lomakrediittejä. Voit silti varata viikon maksamalla puuttuvat ${missingCredits} krediittiä (${additionalCost}€) + 129,90€ vaihtomaksu.`
      );
    }

    setIsBooking(true);
    setShowModal(true);

    // Pass the handleConfirm callback to the modal
    setModalConfirmCallback(() => handleConfirm);
  };

  const checkOffer = (timeshare) => {
    const currentDate = new Date();
    const timeshareStartDate = new Date(timeshare.start_date);
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(currentDate.getDate() + 30);

    return timeshareStartDate <= thirtyDaysFromNow;
  };

  const handleLocationClick = (location) => {
    setExpandedLocation(expandedLocation === location ? null : location);
  };

  const getISOWeek = (date) => {
    const tempDate = new Date(date);
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));
    const week1 = new Date(tempDate.getFullYear(), 0, 4);
    return (
      1 +
      Math.round(
        ((tempDate - week1) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
      )
    );
  };

  const calculateAdjustedCredits = (timeshare) => {
    const currentDate = new Date();
    const timeshareStartDate = new Date(timeshare.start_date);

    const fourWeeksFromNow = new Date();
    fourWeeksFromNow.setDate(currentDate.getDate() + 28);

    if (timeshareStartDate <= fourWeeksFromNow) {
      return Math.floor(timeshare.credits * 0.5);
    }
    return timeshare.credits;
  };

  const initiateStandardPayment = async (timeshare) => {
    console.log("timeshare", timeshare);

    try {
      let paymentAmount = 12990; // €129.90 in cents (default)

      // If a discount is applied, calculate the discounted price
      if (discountApplied && discountCode) {
        const discountResponse = await axios.post(
          `${apiUrl}/auth/validate-discount`,
          {
            code: discountCode,
            user_id: user.id,
            category: "Vaihtomaksut", // Explicitly validate for exchange fees
          }
        );

        const discount = discountResponse.data;
        if (discount.success) {
          const discountPercentage = Number(discount.discount) || 0;
          const discountAmountInCents = Math.floor(
            (paymentAmount * discountPercentage) / 100
          );
          paymentAmount -= discountAmountInCents;
        }
      }

      paymentAmount = Math.max(0, paymentAmount); // Ensure it's not negative

      const paymentData = {
        email: user.email,
        amount: paymentAmount,
        userId: user.id,
        credits: timeshare.adjustedCredits,
        reference: String(timeshare.unit_id),
        order: [
          {
            unitPrice: paymentAmount,
            units: 1,
            productCode: "3", // For standard booking
          },
        ],
      };

      console.log("paymentData", paymentData);

      // Send the payment initiation request
      const response = await axios.post(
        `${apiUrl}/payments/create-payment`,
        paymentData
      );
      const result = response.data;

      if (result.href) {
        // Redirect to Paytrail or another payment processor
        window.location.href = result.href;
      } else {
        console.log("Payment creation failed:", result);
      }
    } catch (error) {
      console.error("Error initiating standard payment:", error);
      setErrorMessage("Virhe maksuprosessin aloittamisessa. Yritä uudelleen.");
      setShowErrorModal(true);
    }
  };

  const initiateDiscountedPayment = async (
    timeshare,
    adjustedCredits,
    discountedAmountInCents
  ) => {
    const missingCredits = adjustedCredits - user.credits; // Calculate missing credits
    const missingCreditsCost = missingCredits > 0 ? missingCredits * 100 : 0; // €1 per credit in cents
    const totalAmount = discountedAmountInCents + missingCreditsCost; // Add the missing credits cost to the discounted exchange fee

    const paymentData = {
      email: user.email,
      amount: totalAmount, // Total payment amount (exchange fee + missing credits)
      userId: user.id,
      credits: adjustedCredits,
      reference: String(timeshare.unit_id),
      order: [
        {
          unitPrice: totalAmount,
          units: 1,
          productCode: "3", // Adjust product code as needed
        },
      ],
    };

    console.log("paymentData", paymentData);

    try {
      const response = await axios.post(
        `${apiUrl}/payments/create-payment`,
        paymentData
      );
      const result = response.data;

      if (result.href) {
        // Redirect to Paytrail or another payment processor
        window.location.href = result.href;
      } else {
        console.error("Payment creation failed:", result);
        setErrorMessage("Maksuprosessin luonti epäonnistui.");
        setShowErrorModal(true);
      }
    } catch (error) {
      console.error("Error initiating discounted payment:", error);
      setErrorMessage("Virhe maksuprosessin aloittamisessa.");
      setShowErrorModal(true);
    }
  };

  const initiateOfferPayment = async (timeshare) => {
    if (!checkOffer(timeshare)) {
      return;
    }

    try {
      const paymentData = {
        email: user.email,
        amount: 9990, // Amount for the offer
        userId: user.id,
        reference: String(timeshare.unit_id),
        order: [
          {
            unitPrice: 9990,
            units: 1,
            productCode: "2",
          },
        ],
      };

      const response = await axios.post(
        `${apiUrl}/payments/create-payment`,
        paymentData
      );
      const result = response.data;

      if (result.href) {
        window.location.href = result.href; // Redirect to Paytrail
      } else {
        console.log("Payment creation failed:", result);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setErrorMessage("Virhe maksuprosessin aloittamisessa. Yritä uudelleen.");
      setShowErrorModal(true);
    }
  };

  const initiateInsufficientCreditsPayment = async (timeshare) => {
    const missingCredits = timeshare.adjustedCredits - user.credits;
    const missingCreditsCost = missingCredits * 100; // €1 per credit in cents
    let paymentAmount = 12990 + missingCreditsCost; // €129.90 exchange fee + missing credits cost

    try {
      // If a discount is applied, calculate the discounted price
      if (discountApplied && discountCode) {
        const discountResponse = await axios.post(
          `${apiUrl}/auth/validate-discount`,
          {
            code: discountCode,
            user_id: user.id,
            category: "Vaihtomaksut", // Explicitly validate for exchange fees
          }
        );

        const discount = discountResponse.data;
        if (discount.success) {
          const discountPercentage = Number(discount.discount) || 0;
          const discountAmountInCents = Math.floor(
            (12990 * discountPercentage) / 100 // Only apply the discount to the exchange fee
          );
          paymentAmount -= discountAmountInCents;
        }
      }

      paymentAmount = Math.max(0, paymentAmount); // Ensure it's not negative

      const paymentData = {
        email: user.email,
        amount: paymentAmount,
        userId: user.id,
        credits: timeshare.adjustedCredits,
        reference: String(timeshare.unit_id),
        order: [
          {
            unitPrice: paymentAmount, // Total payment amount
            units: 1,
            productCode: "4",
          },
        ],
      };

      const response = await axios.post(
        `${apiUrl}/payments/create-payment`,
        paymentData
      );
      const result = response.data;

      if (result.href) {
        // Redirect to the payment page
        window.location.href = result.href;
      } else {
        console.log("Payment creation failed:", result);
      }
    } catch (error) {
      console.error("Error initiating payment with missing credits:", error);
      setErrorMessage("Virhe maksuprosessin aloittamisessa. Yritä uudelleen.");
      setShowErrorModal(true);
    }
  };

  const validateDiscountCode = async (
    discountCode,
    timeshare,
    adjustedCredits
  ) => {
    console.log("Validating discount code");

    try {
      // Call the backend API to validate the discount code
      const response = await axios.post(`${apiUrl}/auth/validate-discount`, {
        code: discountCode,
        user_id: user.id, // Send the user ID for validation
        category: "Vaihtomaksut", // Specify the category for exchange fee discounts
      });

      console.log("Response from validation:", response.data);

      const { success, error, discount, code } = response.data;

      if (!success) {
        // Handle specific backend error codes
        switch (code) {
          case "INVALID_CODE":
            setDiscountErrorMessage("Alennuskoodi on virheellinen."); // Invalid code
            break;
          case "NOT_YOUR_CODE":
            setDiscountErrorMessage("Alennuskoodi ei ole sinulle."); // Not assigned to this user
            break;
          case "EXPIRED_CODE":
            setDiscountErrorMessage("Alennuskoodi on vanhentunut."); // Expired code
            break;
          case "CODE_ALREADY_USED":
            setDiscountErrorMessage("Alennuskoodi on jo käytetty."); // Already used
            break;
          default:
            setDiscountErrorMessage(
              "Tuntematon virhe alennuskoodin tarkistuksessa."
            );
        }
        return; // Stop further execution if the discount code validation fails
      }

      // Calculate the discounted amount
      const baseAmountInCents = 12990; // Base price in cents for exchange fees
      const discountPercentage = Number(discount) || 0; // Get discount percentage

      // Calculate the discount amount in cents
      const discountAmountInCents = Math.floor(
        (baseAmountInCents * discountPercentage) / 100
      );

      // Apply the discount to the base amount
      const discountedAmountInCents = baseAmountInCents - discountAmountInCents;

      console.log("discountPercentage", discountPercentage); // Log the discount percentage
      console.log("discountAmountInCents", discountAmountInCents); // Log the calculated discount amount
      console.log("discountedAmountInCents", discountedAmountInCents); // Log the final discounted amount

      // Proceed with discounted payment
      await initiateDiscountedPayment(
        timeshare,
        adjustedCredits,
        discountedAmountInCents
      );
    } catch (error) {
      console.error("Error validating discount code:", error);

      // Handle error from backend response
      if (error.response && error.response.data) {
        const { code } = error.response.data;
        switch (code) {
          case "INVALID_CODE":
            setDiscountErrorMessage("Alennuskoodi on virheellinen.");
            break;
          case "NOT_YOUR_CODE":
            setDiscountErrorMessage("Alennuskoodi ei ole sinulle.");
            break;
          case "EXPIRED_CODE":
            setDiscountErrorMessage("Alennuskoodi on vanhentunut.");
            break;
          case "CODE_ALREADY_USED":
            setDiscountErrorMessage("Alennuskoodi on jo käytetty.");
            break;
          default:
            setDiscountErrorMessage(
              "Tuntematon virhe alennuskoodin tarkistuksessa."
            );
        }
      } else {
        // Fallback for unexpected errors
        setDiscountErrorMessage(
          "Virhe alennuskoodin tarkistuksessa. Yritä uudelleen."
        );
      }
    }
  };

  return (
    <div className="min-h-screen bg-transparent flex flex-col justify-start p-4">
      <div className="flex justify-center">
        <div>
          <div className="bg-white bg-opacity-80 rounded-xl p-4 m-4">
            <h1 className="text-lg md:text-3xl font-bold mx-4 text-center">
              Suomen RCI-tyyppinen lomanvaihto
            </h1>
          </div>
          <div className="bg-white bg-opacity-80 rounded-xl p-4 m-4">
            <h2 className="text-lg md:text-2xl font-bold text-red-500 mx-4 text-center">
              Varaa heti, kaikki lomat 129€/vk (myös Holiday Club kohteet)
            </h2>
          </div>
        </div>
      </div>

      {/* Filter for logged-in users */}
      {user && user.depositMade === 1 && (
        <div className="flex justify-center">
          <Filter
            onFilterChange={onFilterChange}
            checkAvailability={checkAvailability}
            availableWeeks={availableWeeks}
            availableBedCounts={availableBedCounts}
            availableSaunaOptions={availableSaunaOptions}
            availableBalconyOptions={availableBalconyOptions}
          />
        </div>
      )}

      <Modal
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message={errorMessage}
      />

      <div className="flex items-center justify-center">
        {user ? (
          user.membership?.active === 1 ? (
            user.depositMade === 1 ? (
              filteredTimeShares.length === 0 ? (
                <p>Ei hakuehtoja vastaavia lomia saatavilla.</p>
              ) : (
                <>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {filteredTimeShares.map((timeshare) => (
                      <Card
                        key={timeshare.unit_id}
                        timeshare={timeshare}
                        adjustedCredits={calculateAdjustedCredits(timeshare)}
                        buttonText="Varaa nyt"
                        onClick={() =>
                          handleBookingClick(
                            timeshare,
                            calculateAdjustedCredits(timeshare)
                          )
                        }
                        offer={checkOffer(timeshare)}
                      />
                    ))}
                  </div>
                  {showModal && selectedTimeshare && (
                    <ConfirmationModal
                      prompt={message}
                      onConfirm={modalConfirmCallback}
                      onCancel={() => setShowModal(false)}
                      isBooking={isBooking}
                      errorMessage={discountErrorMessage}
                    />
                  )}
                </>
              )
            ) : (
              <div className="bg-white rounded-lg shadow-lg p-4 m-4 bg-opacity-50">
                <p className="text-xl text-center m-4">
                  Talleta osake päästäksesi varaamaan vapaita lomaviikkoja.
                </p>
                <div className="flex justify-center mt-4">
                  <a href="/profiili">
                    <button className="bg-teal-600 text-white px-4 py-2 rounded-lg hover:bg-teal-700 transition">
                      Talleta
                    </button>
                  </a>
                </div>
              </div>
            )
          ) : (
            <div className="bg-white flex flex-col justify-center rounded-lg shadow-lg p-4 m-4 bg-opacity-50">
              <p className="text-xl text-center m-4">
                Sinulla ei ole aktiivista jäsenyyttä.
              </p>
              <button
                onClick={() => navigate("/tervetuloa")}
                className="flex w-3/4 lg:w-1/2 justify-center mx-auto rounded-3xl bg-teal-600 px-5 py-3 text-md lg:text-xl font-roboto leading-6 text-white shadow-sm hover:bg-teal-700 focus-visible:outline focus:bg-teal-700 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange"
              >
                Osta jäsenyys
              </button>
            </div>
          )
        ) : (
          <div className="flex flex-col justify-center w-9/12">
            <div className="flex flex-col items-center bg-white rounded-xl bg-opacity-50 m-4 w-full lg:w-1/2 mx-auto">
              <p className="text-xl text-center m-4">
                Kirjaudu sisään ja talleta viikko, niin pääset selaamaan
                kohteita ja varaamaan vapaita viikkoja.
              </p>
              <button
                className="bg-teal-600 text-white font-semibold text-lg rounded-lg m-4 p-2 w-1/2 md:w-1/4 hover:bg-teal-700 focus:outline-none"
                onClick={() => navigate("/kirjaudu")}
              >
                Kirjaudu
              </button>
            </div>

            <div className="flex flex-col md:flex-row justify-center items-center mx-auto w-full">
              <div className="w-max md:w-1/2">
                <TimeshareMap />
              </div>
              <div
                className="bg-white rounded-xl w-full md:w-2/5 p-4 shadow-lg"
                ref={locationRef}
              >
                <h2 className="text-xl font-bold mb-2 text-center">
                  Kohteet (mm. kaikki Holiday Clubin kohteet)
                </h2>
                <ul className="grid grid-cols-2 sm:grid-cols-3 gap-y-1.5">
                  {Object.keys(locations).map((location) => (
                    <li key={location} className="group">
                      <button
                        onClick={() => handleLocationClick(location)}
                        className="text-md md:text-lg text-gray-800 hover:text-teal-600 focus:outline-none transition-all duration-300 ease-in-out w-full text-left"
                      >
                        {location.charAt(0).toUpperCase() + location.slice(1)}
                      </button>
                      {expandedLocation === location && (
                        <ul className="ml-4 mt-1 space-y-1 overflow-y-auto max-h-40">
                          {locations[location].map((property) => (
                            <li
                              key={property}
                              className="text-sm text-gray-600 group-hover:text-gray-800 transition-all duration-300 ease-in-out"
                            >
                              {property}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;

const locations = {
  airisto: ["Airiston Fregatti", "Airiston Kuunari", "Airiston Kutteri"],
  ellivuori: ["Ellin Loisto I"],
  kivijärvi: ["Hannunkiven Lomakylä"],
  himos: [
    "Himoksen Aurinkopaikka",
    "Himoksen Tähti I",
    "Villas Himos I",
    "Villas Himos II",
    "Villas Himos III",
  ],
  hoilola: ["Onnenvirta II", "Onnenvirta III"],
  ikaalinen: ["Ikaalisten Mäntypiha"],
  imatra: ["Imatra Spa Viikko Oy"],
  kalajoki: [
    "Särkkäin lomaparatiisi I",
    "Särkkäin lomaparatiisi II",
    "Rantabeach",
    "Kalajoen Keidas",
  ],
  vuokatti: [
    "Katinkullan Golfharju",
    "Katinkullan Hiekkaniemi",
    "Katinkullan Kiinteistöt",
    "Katinkultaniemi",
    "Katinkultaranta",
    "Katinkullan Rantahovi",
    "Katinkulta Residence",
    "Katinkullan Golfpuisto",
    "Katinkulta Spa Lodge 1",
    "Katinkulta Spa Lodge 2",
    "Villas Katinkulta Spa 1",
    "Villas Katinkulta Spa 1 Lodge",
    "Villas Katinkulta Spa 2",
    "Villas Katinkulta Golf Park",
    "Vuokatti Country Club",
    "Vuokatin Kulta-Katti",
    "Vuokatin Lepokatti",
  ],
  koli: ["Kolin Kukkula"],
  kihniö: [
    "Pyhäniemi II",
    "Pyhäniemi III",
    "Pyhäniemi IV",
    "Pyhäniemi V",
    "Pyhäniemi VI",
    "Pyhäniemi VII",
    "Pyhäniemi VIII",
  ],
  kuortane: ["Kuortaneen Liikuntahotelli"],
  kuusamo: [
    "Kuusamon Lampitropiikki",
    "Kuusamon Tähti 1",
    "Kuusamon Lomaparatiisi",
    "Kuusamon Lomatropiikki",
    "Kuusamon Rantatropiikki",
    "Kuusamon Rantatropiikki 2",
    "Petäjälampi 6 Lodge",
    "Petäjälammenranta 7 Lodge",
  ],
  laukaa: ["Pitkäniemi III"],
  levi: [
    "Levi-Rakkavaara Club 1",
    "Rakkavaara Club Int. Ltd",
    "Abgott",
    "Aruudenia",
  ],
  naantali: ["Naantalin kylpyläranta", "Sunborn Vacation Club 1"],
  punkaharju: ["Hiekkaharju 1", "Hiekkaharju 2"],
  pyhä: ["Onninpyhä", "Pyhänhovi", "Pyhä HolySuites"],
  rönnäs: ["Rönnäs Country Club"],
  ruka: [
    "Rukan Lomakylä I",
    "RukaVillage Suites 1",
    "Kuusamon Pulkkajärvi 3",
    "Kuusamon Pulkkajärvi 4",
    "Kuusamon Pulkkajärvi 5",
    "Kuusamon Pulkkajärvi 6",
  ],
  saimaa: [
    "Anttilankaari 6",
    "Anttilankaari 8",
    "Anttilankaari 10",
    "Vipelentie 35",
    "Saimaanranta",
    "Saimaanranta 2",
    "Saimaanranta 3",
    "Saimaanrantapuisto",
    "Saimaan Keskuspuisto Lodge",
    "Saimaa Pearl Lodge 1",
    "Saimaa Spa Lodge 1",
    "Saimaa Spa Lodge 2",
    "Saimaa Spa Lodge 3",
  ],
  salla: [
    "Sallas huoneistot",
    "Sallan tähti",
    "Sallan Tunturitähti",
    "Sallatunturin Kelorinne",
    "Sallan Eraustähti",
    "Villas Sallatunturi 1",
    "Villas Sallatunturi 2",
  ],
  saariselkä: [
    "Kermikkä",
    "Siulaselkä",
    "Kelotirro",
    "Riekonraito",
    "Laavutieva",
    "Tirrolampi",
    "Nilihonka",
    "Saariselkä Spa Lodge 2",
    "Kelotähti 1 Lodge",
    "Ruskarinne",
  ],
  tahko: [
    "Tahkotime",
    "Leppätahko",
    "Nilsiän Rentotahko",
    "Spa Suites Black",
    "Spa Suites White",
  ],
  tampere: [
    "Näsijärven Kimallus",
    "Lapiinniemi I",
    "Lapinniemi II",
    "Lapinniemi III",
    "Lapinniemi IV",
    "Lapinniemi V",
    "Lapinniemi VI",
    "Lapinniemi VII",
    "Lapinniemi VIII",
    "Lapinniemi IX",
    "Lapinniemi X",
    "Lapinniemi XI",
    "Lapinniemi XII",
    "Lapinniemi XIII",
    "Lapinniemi XIV",
    "Lapinniemi XV",
    "Lapinniemi XVI",
    "Lapinniemi XVII",
    "Lapinniemi XVIII",
    "Lapinniemi XIX",
    "Lapinniemi XX",
  ],
  turku: ["Caribia Spa Lodge 1", "HC Villas Turun Caribia"],
  vierumäki: [
    "HC Villas Vierumäki 1",
    "HC Villas Vierumäki 2",
    "Vierumäki Golf Resort",
  ],
  ylläs: [
    "Kolarin Siepakka",
    "Kesänki",
    "Kuer",
    "Muiro",
    "Musko",
    "Mokko",
    "Ylläksen Rautamajat",
    "Ylläs Saaga",
    "Ylläs viikko 1",
    "Ylläksen lomaviikot",
  ],
  ähtäri: ["Ähtärin Lomakylä", "Moksunhonka 1"],
  ulkomaat: [
    "Jardin Amadores",
    "Playa Amadores",
    "Puerto Calma",
    "Sol Amadores",
    "Vista Amadores",
    "Åre",
  ],
};
