import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer.js";
import banner3 from "../../assets/banner-x3-lomahuuto.png";
import banner4 from "../../assets/banner-x3-sunholidays.png";
import banner5 from "../../assets/banner-x-lomahuuto.png";
import banner6 from "../../assets/banner-x-sunholidays.png";
import cabin from "../../assets/cabin.jpg"; // Background image

const Layout = ({ children }) => {
  return (
    <div className="relative min-h-screen">
      {/* Background container to ensure background covers everything */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-fixed"
        style={{
          backgroundImage: `url(${cabin})`, // Apply background image globally
        }}
      ></div>

      {/* The main content should be on top of the background */}
      <div className="relative z-10 flex flex-col min-h-screen">
        <NavBar />

        {/* Content container with padding to accommodate the navbar */}
        <div className="pt-20 md:pt-40 lg:pt-44 flex-grow">
          {/* Children content */}
          <div className="flex-grow min-h-screen">{children}</div>
        </div>

        {/* Bottom banners */}
        <div className="hidden md:flex flex-row justify-center w-full bg-transparent p-4 text-center space-x-4">
          <a
            href="https://sunholidays.fi"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-lg hover:cursor-pointer flex items-center"
          >
            <img
              src={banner4}
              alt="banner4"
              className="md:h-24 lg:h-32 xl:h-40 2xl:h-48 3xl:h-60 rounded-lg"
            />
          </a>
          <a
            href="https://lomahuuto.fi"
            target="_blank"
            rel="noopener noreferrer"
            className="rounded-lg hover:cursor-pointer flex items-center"
          >
            <img
              src={banner3}
              alt="banner3"
              className="md:h-24 lg:h-32 xl:h-40 2xl:h-48 3xl:h-60 rounded-lg"
            />
          </a>
        </div>

        {/* Mobile bottom banner */}
        <div className="md:hidden flex flex-col w-full bg-transparent p-4 text-center">
          <a
            href="https://sunholidays.fi"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-auto rounded-lg hover:cursor-pointer flex items-center"
          >
            <img src={banner6} alt="banner6" className="h-52 rounded-lg" />
          </a>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Layout;
