import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserWeeks, setSelectedUserWeeks] = useState(null); // State to store the selected user's weeks
  const [selectedUserBookings, setSelectedUserBookings] = useState(null); // State to store the selected user's bookings
  const [expandedUserId, setExpandedUserId] = useState(null); // State to store which user's weeks are expanded
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      // Fetch users
      const response = await axios.get(`${apiUrl}/auth/users`, {
        withCredentials: true,
      });
      const usersData = response.data.users;
      console.log("users", response.data.users);

      // Fetch timeshare counts
      const timeshareResponse = await axios.get(
        `${apiUrl}/timeshares/timeshareunits/count`
      );
      const timeshareCounts = timeshareResponse.data;

      // Fetch bookings counts
      const bookingsResponse = await axios.get(
        `${apiUrl}/timeshares/bookings/confirmed/count`
      );
      const bookingsCounts = bookingsResponse.data;

      // Fetch credits for all users
      const creditsResponse = await axios.get(`${apiUrl}/auth/wallet/all`, {
        withCredentials: true,
      });
      const creditsByUser = creditsResponse.data.creditsByUser;

      // Merge timeshare counts, bookings counts, and total credits into users data
      const updatedUsers = usersData.map((user) => {
        const timeshareCount =
          timeshareCounts.find((count) => count.user_id === user.user_id)
            ?.owned_units || 0;
        const bookingsCount =
          bookingsCounts.find((count) => count.user_id === user.user_id)
            ?.confirmed_bookings || 0;

        // Calculate the total credits for each user
        const totalCredits = (creditsByUser[user.user_id] || []).reduce(
          (sum, credit) => sum + credit.amount,
          0
        );

        return {
          ...user,
          timeshareCount,
          bookingsCount,
          totalCredits, // Include total credits amount for each user
        };
      });

      setUsers(updatedUsers);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const handleWeeksClick = (userId) => {
    if (expandedUserId === userId) {
      // Collapse if already expanded
      setExpandedUserId(null);
      setSelectedUserWeeks(null);
      return;
    }

    // Filter the weeks based on user_id
    const userWeeks = users
      .find((user) => user.user_id === userId)
      .weeks.filter((week) => week.user_id === userId);

    setSelectedUserWeeks(userWeeks); // Store the filtered weeks
    setExpandedUserId(userId); // Set the expanded user ID
  };

  const handleBookingsClick = (userId) => {
    if (expandedUserId === userId) {
      // Collapse if already expanded
      setExpandedUserId(null);
      setSelectedUserBookings(null);
      return;
    }

    // Filter the bookings based on user_id
    const userBookings = users
      .find((user) => user.user_id === userId)
      .bookings.filter((booking) => booking.user_id === userId);

    setSelectedUserBookings(userBookings); // Store the filtered bookings
    setExpandedUserId(userId); // Set the expanded user ID
  };

  const logInAsUser = async (userId) => {
    try {
      const response = await axios.post(
        `${apiUrl}/auth/impersonate`,
        { userId },
        { withCredentials: true }
      );

      console.log("Impersonating user:", response.data);
      navigate("/");
    } catch (err) {
      console.error("Error impersonating user:", err);
    }
  };

  return (
    <div className="min-h-screen bg-transparent">
      <div className="flex justify-center items-center">
        <table className="table-auto bg-white rounded-lg">
          <thead>
            <tr>
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">Nimi</th>
              <th className="px-4 py-2">Sähköposti</th>
              <th className="px-4 py-2">Puhelin</th>
              <th className="px-4 py-2">Krediitit</th>
              <th className="px-4 py-2">Omistetut viikot</th>
              <th className="px-4 py-2">Vuokratut viikot</th>
              <th className="px-4 py-2">Rekisteröitymispäivä</th>
              <th className="px-4 py-2">Jäsenyys alkoi</th>
              <th className="px-4 py-2">Jäsenyys päättyy</th>
              <th className="px-4 py-2">Talletukset</th>
              <th className="px-4 py-2">Admin</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <>
                <tr key={user.user_id}>
                  <td className="border px-4 py-2">{user.user_id}</td>
                  <td className="border px-4 py-2">
                    {user.name
                      ? user.name
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : "N/A"}
                  </td>
                  <td className="border px-4 py-2">
                    <a
                      href={`mailto:${user.email}`}
                      className="text-blue-500 underline"
                    >
                      {user.email}
                    </a>
                  </td>

                  <td className="border px-4 py-2">
                    {user.phone_number ? user.phone_number : "-"}
                  </td>
                  <td className="border px-4 py-2">{user.totalCredits}</td>
                  <td
                    className="border px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleWeeksClick(user.user_id)}
                  >
                    {user.timeshareCount}
                  </td>
                  <td
                    className="border px-4 py-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleBookingsClick(user.user_id)}
                  >
                    {user.bookingsCount}
                  </td>
                  <td className="border px-4 py-2">
                    {new Date(user.registration_date).toLocaleDateString()}{" "}
                  </td>
                  <td className="border px-4 py-2">
                    {user.membership_start
                      ? new Date(user.membership_start).toLocaleDateString()
                      : "N/A"}
                  </td>
                  <td className="border px-4 py-2">
                    {user.membership_end
                      ? new Date(user.membership_end).toLocaleDateString()
                      : "N/A"}
                  </td>
                  <td className="border px-4 py-2">
                    {user.deposit_count ? user.deposit_count : 0}
                  </td>
                  <td className="border px-4 py-2">
                    {user.isAdmin === 1 ? "Kyllä" : "Ei"}
                  </td>
                  <td>
                    <button
                      className="border px-4 py-2 hover:underline"
                      onClick={() => logInAsUser(user.user_id)}
                    >
                      Kirjaudu sisään käyttäjänä
                    </button>
                  </td>
                </tr>
                {expandedUserId === user.user_id && selectedUserWeeks && (
                  <tr>
                    <td colSpan="7" className="border px-4 py-2">
                      <h3 className="font-bold">Omistetut viikot:</h3>
                      <ul>
                        {selectedUserWeeks.map((week) => (
                          <li key={week.unit_id}>
                            {week.property_name} -{" "}
                            {new Date(week.start_date).toLocaleDateString()} -{" "}
                            {new Date(week.end_date).toLocaleDateString()}
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )}
                {expandedUserId === user.user_id && selectedUserBookings && (
                  <tr>
                    <td colSpan="7" className="border px-4 py-2">
                      <h3 className="font-bold">Vuokratut viikot:</h3>
                      <ul>
                        {selectedUserBookings.map((booking) => (
                          <li key={booking.unit_id}>
                            {booking.property_name} -{" "}
                            {new Date(booking.start_date).toLocaleDateString()}{" "}
                            - {new Date(booking.end_date).toLocaleDateString()}{" "}
                            (Varattu:{" "}
                            {new Date(
                              booking.booking_date
                            ).toLocaleDateString()}
                            )
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
